import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { get, isEmpty, head } from 'lodash';
import moment from 'moment';
import validate from '../../../../actions/validate';
import {
    RenderField,
    RenderItemSelector,
    RenderDatePicker,
    RenderCheckbox,
    RenderMultiSelectField,
} from '../../../../../actions/renderField';
import MedicareCardSample from '../../../../../../../assets/img/Medicare_card_new.jpg';
import {
    RadioSelector,
    YesNoSelector,
} from '../../../../../components/FormReuse/RadioSelector';
import AddressForm from '../../../../../components/FormReuse/AddressForm';
import {
    YES_NO_OPTION,
    RECEIPT_PREF,
    EDIT_SECTION,
    PAGES,
    SUBPAGES,
    PAGE_NAME,
} from '../../../../../constants/enum';
import { LENGTH_RESTRICTIONS } from '../../../../../constants/input';
import {
    RELATIONSHIP_OPTIONS,
    LANGUAGE_OPTIONS,
    GENDER_OPTIONS_2024,
    GENDER_OPTIONS,
    RELATIONSHIP_MAP,
    ETHNICITY_OPTIONS,
    RACE_OPTIONS,
    GENDER_MAP_2024,
    GENDER_MAP,
} from '../../../../../constants/options';
import {
    normalizeAlphanumeric,
    normalizePhone,
} from '../../../../../util/normalize';
import { ENROLLER_TYPE } from '../../../../../../../constants/identifiers';
import ALL_STATES, {
    AHC_ALLOWED_STATES,
} from '../../../../../../../constants/states';
import Button, {
    NextButton,
    BackButton,
} from '../../../../../../Common/Button/Button';
import './Second-ClientInfo.scss';
import FieldTrimmed from '../../../../../actions/FieldTrimmed';
import {
    verifyCMSEligibility,
    useCheckEnrollerTypeApplicant,
} from './helperFunctions';
import { useAppInsightContext } from '../../../../../../../contexts/appInsight';
import { EnrollmentFieldOptions } from 'enums/Enrollment/EnrollmentFieldOptions';
import { getEnrollmentFieldOptions } from 'api/enrollment';

const zipcodePopoverMessage =
    'This zip code matches the zip code entered before plan selection. Please start from the beginning if you have a different zip code.';
const statePopoverMessage =
    'The state in your permanent address is determined by the zip code entered before plan selection. Please enter a different zip code if you live in a different state.';
const getEffectiveDateMap = (coverageYear) => {
    // can test different dates by setting TODAYS_DATE = moment('2020-11-01T00:00:01') or moment('2023-02-31T23:59:59'), etc
    const TODAYS_DATE = moment().format('YYYY-MM-DDTHH:mm:ssZ');

    const INITIAL_SELECTION_DATES = [
        moment(TODAYS_DATE).date(1).add(1, 'months'),
        moment(TODAYS_DATE).date(1).add(2, 'months'),
        moment(TODAYS_DATE).date(1).add(3, 'months'),

        // For Debugging during Non-AEP seasons
        moment(TODAYS_DATE).date(1).add(15, 'months').year().toString() ===
        coverageYear
            ? moment(`1/1/${coverageYear}`)
            : null,
    ].filter((value) => !isEmpty(value));

    const PROPOSED_EFFECTIVE_DATE_SELECTION_DATES =
        INITIAL_SELECTION_DATES.filter(
            (date) => date.year().toString() === coverageYear
        );
    const PROPOSED_EFFECTIVE_DATE_SELECTION_VALUES =
        PROPOSED_EFFECTIVE_DATE_SELECTION_DATES.map((date) =>
            date.format('YYYY-MM-DD')
        );
    const PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS =
        PROPOSED_EFFECTIVE_DATE_SELECTION_DATES.map((date) =>
            date.format('MM/DD/YYYY')
        );

    let EFFECTIVE_DATE_MAP = {};
    EFFECTIVE_DATE_MAP[`${PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS[0]}`] =
        PROPOSED_EFFECTIVE_DATE_SELECTION_VALUES[0];
    EFFECTIVE_DATE_MAP[`${PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS[1]}`] =
        PROPOSED_EFFECTIVE_DATE_SELECTION_VALUES[1];
    EFFECTIVE_DATE_MAP[`${PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS[2]}`] =
        PROPOSED_EFFECTIVE_DATE_SELECTION_VALUES[2];
    if (EFFECTIVE_DATE_MAP[`${PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS[3]}`])
        EFFECTIVE_DATE_MAP[`${PROPOSED_EFFECTIVE_DATE_SELECTION_KEYS[3]}`] =
            PROPOSED_EFFECTIVE_DATE_SELECTION_VALUES[3];
    return EFFECTIVE_DATE_MAP;
};
const ClientInformComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [verifyMessage, setVerifyMessage] = useState(null);
    const [selfIdentifyOptions, setSelfIdentifyOptions] = useState([]);
    const [fieldOptionsLoading, setFieldOptionsLoading] = useState(false);

    const {
        stateAbbrFromZip,
        enrollment,
        handleSubmit,
        previousPage,
        change,
        goToRef,
        setGoToRef,
    } = props;
    const applicantDetailsRef = useRef(null);
    const emergencyContactRef = useRef(null);
    const medicareInfoRef = useRef(null);
    const appInsights = useAppInsightContext();
    const effectiveDateMap = getEffectiveDateMap(
        enrollment?.findPlan.coverageYear
    );
    const is2024 = get(enrollment, 'findPlan.coverageYear') === '2024';
    const saveFormattedDate = (path, value) => {
        change(path, value);
    };

    useEffect(() => {
        window._mfq.push(['newPageView', 'Enrollment-ApplicantInfoPage']);
        window.analytics.page('Applicant Information');

        appInsights.startTrackPage(
            PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.APPLICANT_INFO]
        );

        const scrollToRef = async () => {
            switch (goToRef) {
                case EDIT_SECTION.MEDICARE:
                    medicareInfoRef.current.scrollIntoView();
                    break;
                case EDIT_SECTION.APPLICANT_INFO:
                    applicantDetailsRef.current.scrollIntoView();
                    break;
                case EDIT_SECTION.EMERGENCY_CONTACT:
                    emergencyContactRef.current.scrollIntoView();
                    break;
                default:
                    window.scrollTo(0, 0);
            }
        };
        scrollToRef().then(setGoToRef(null));

        if (
            isEmpty(
                get(enrollment, 'enrolling.clientInfo.proposedEffectiveDate')
            )
        ) {
            change(
                'enrollment.enrolling.clientInfo.proposedEffectiveDate',
                head(Object.values(effectiveDateMap))
            );
        }

        getFieldOptions();

        return () => {
            appInsights.stopTrackPage(
                PAGE_NAME[PAGES.ENROLLMENT][SUBPAGES.APPLICANT_INFO],
                null,
                {
                    applicationId: get(enrollment, 'applicationId'),
                }
            );
        };
    }, []);

    const getFieldOptions = async () => {
        setFieldOptionsLoading(true);

        const selfIdentifyOptionsResponse = await getEnrollmentFieldOptions(
            EnrollmentFieldOptions.SelfIdentify
        );
        const _selfIdentifyOptions = await selfIdentifyOptionsResponse?.json();
        setSelfIdentifyOptions(_selfIdentifyOptions ?? []);

        setFieldOptionsLoading(false);
    };

    useCheckEnrollerTypeApplicant(
        change,
        enrollment.enrolling.clientInfo.enrollerType
    );

    useEffect(() => {
        change(
            'enrollment.enrolling.clientInfo.permanentZipCode',
            enrollment.findPlan.zipCode
        );
        change(
            'enrollment.enrolling.clientInfo.permanentState',
            stateAbbrFromZip
        );
    }, []);

    const isEmailCommunicationPreference = !(
        get(enrollment, 'enrolling.clientInfo.receiveByMailEobPartC') &&
        get(enrollment, 'enrolling.clientInfo.receiveByMailEobPartD') &&
        get(enrollment, 'enrolling.clientInfo.receiveByMailAnoc')
    );
    return (
        <div className="client-info-form">
            <div className="text-left">
                <h1 className="enrollment-title my-4">Applicant Information</h1>
                <div className="row">
                    <div className="col-md-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][proposedEffectiveDate]"
                            label="Proposed Effective Date"
                            list={Object.keys(effectiveDateMap)}
                            valueMap={effectiveDateMap}
                            component={RenderItemSelector}
                        />
                    </div>
                </div>
                <h5 className="enrollment-title my-4" ref={medicareInfoRef}>
                    Medicare Information
                </h5>
                <div className="medicare-information">
                    <span>
                        <img
                            className="medicare-card-preview"
                            src={MedicareCardSample}
                            alt="medicare_card"
                        />
                    </span>

                    <p className="pl-3">
                        Please take out your red, white, and blue Medicare card
                        to complete this section.
                        <br />
                        In the spaces provided, enter your Medicare Number (do
                        not enter dashes) and the Effective Dates for your Part
                        A and Part B coverage.
                        <br />
                        You must have Medicare Part A and Part B to join a
                        Medicare Advantage plan.
                    </p>
                </div>
                <div className="row py-3 medicare-info-form">
                    <div className="col-lg-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][medicareID]"
                            type="text"
                            required
                            charLimit={LENGTH_RESTRICTIONS.MEDICARE_ID}
                            component={RenderField}
                            normalize={(val) =>
                                normalizeAlphanumeric(val).toUpperCase()
                            }
                            label="Medicare ID #"
                            disabled={loadingState}
                        />
                    </div>
                    <div className="col-lg-3">
                        <FieldTrimmed
                            id="medicareInfo-lastName"
                            name="enrollment[enrolling][clientInfo][lastName]"
                            type="text"
                            required
                            charLimit={LENGTH_RESTRICTIONS.LAST_NAME}
                            disabled={loadingState}
                            component={RenderField}
                            label="Last Name"
                        />
                    </div>
                    <div className="col-lg-3">
                        <Field
                            id="VerifyMedicare-DateOfBirth"
                            name="enrollment[enrolling][clientInfo][dateOfBirth]"
                            type="date"
                            required
                            component={RenderDatePicker}
                            start="Decade"
                            pastOnly
                            disabled={loadingState}
                            saveFormattedDate={saveFormattedDate}
                            label="Date Of Birth"
                        />
                    </div>
                    <div className="col-lg-3 verify-btn-wrapper">
                        <Button
                            color="primary"
                            className="verify-button"
                            onClick={() => {
                                window.analytics.track(
                                    'Verify CMS Eligibility'
                                );
                                verifyCMSEligibility(
                                    props,
                                    setLoadingState,
                                    setVerifyMessage
                                );
                            }}
                            disabled={loadingState}
                        >
                            Verify
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][medicareEligibilityDatePartA]"
                            type="date"
                            required
                            component={RenderDatePicker}
                            disabled={loadingState}
                            saveFormattedDate={saveFormattedDate}
                            label="Medicare Part A Eligibility Date"
                        />
                    </div>
                    <div className="col-lg-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][medicareEligibilityDatePartB]"
                            type="date"
                            required
                            component={RenderDatePicker}
                            saveFormattedDate={saveFormattedDate}
                            disabled={loadingState}
                            label="Medicare Part B Eligibility Date"
                        />
                    </div>
                    <div className="col-lg-3">
                        {verifyMessage && (
                            <span
                                className={`${
                                    verifyMessage.includes(
                                        'Success: Applicant Verified'
                                    )
                                        ? 'text-success'
                                        : 'text-danger'
                                } verify-message`}
                            >
                                {verifyMessage.map((message, idx) => (
                                    <React.Fragment key={`message_${idx}`}>
                                        {message}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </span>
                        )}
                    </div>
                </div>

                {/* Client Details Form */}
                <h5 className="enrollment-title my-4" ref={applicantDetailsRef}>
                    Client Details
                </h5>
                <div className="row">
                    <div className="col-lg-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][firstName]"
                            type="text"
                            required
                            charLimit={LENGTH_RESTRICTIONS.FIRST_NAME}
                            disabled={loadingState}
                            component={RenderField}
                            label="First Name"
                        />
                    </div>
                    <div className="col-lg-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][middleInitial]"
                            type="text"
                            disabled={loadingState}
                            charLimit={LENGTH_RESTRICTIONS.MIDDLE_INITIAL}
                            component={RenderField}
                            label="Middle Initial"
                        />
                    </div>
                    <div className="col-lg-3">
                        <FieldTrimmed
                            id="clientDetails-lastName"
                            name="enrollment[enrolling][clientInfo][lastName]"
                            type="text"
                            required
                            disabled={true}
                            charLimit={LENGTH_RESTRICTIONS.LAST_NAME}
                            component={RenderField}
                            label="Last Name"
                        />
                    </div>
                    <div className="col-lg-3">
                        <Field
                            id="ClientInfo-DateOfBirth"
                            name="enrollment[enrolling][clientInfo][dateOfBirth]"
                            type="date"
                            required
                            component={RenderField}
                            disabled={true}
                            label="Date Of Birth"
                        />
                    </div>
                    <div className="col-lg-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][gender]"
                            type="select"
                            required
                            list={is2024 ? GENDER_OPTIONS_2024 : GENDER_OPTIONS}
                            addPlaceholder
                            valueMap={is2024 ? GENDER_MAP_2024 : GENDER_MAP}
                            disabled={loadingState}
                            component={RenderItemSelector}
                            label="Gender"
                        />
                    </div>
                    {!is2024 && (
                        <div className="col-lg-3">
                            <Field
                                name="enrollment[enrolling][clientInfo][genderSelfIdentificationCode]"
                                type="select"
                                list={selfIdentifyOptions?.map(
                                    (option) => option.description
                                )}
                                valueMap={selfIdentifyOptions?.reduce(
                                    (prev, curr) => ({
                                        ...prev,
                                        [curr.description]: curr.value,
                                    }),
                                    {}
                                )}
                                disabled={fieldOptionsLoading}
                                component={RenderItemSelector}
                                label="Self Identify"
                                addPlaceholder
                            />
                        </div>
                    )}
                </div>
                {/* Race and Ethnicity (2023 Additions) */}
                <div>
                    <Field
                        name="enrollment[enrolling][clientInfo][ethnicity]"
                        label="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply."
                        options={ETHNICITY_OPTIONS}
                        component={RenderMultiSelectField}
                    />
                </div>
                <div>
                    <Field
                        name="enrollment[enrolling][clientInfo][race]"
                        label="What’s your race? Select all that apply."
                        options={RACE_OPTIONS}
                        component={RenderMultiSelectField}
                    />
                </div>
                <div id="language-form">
                    <span id="language-label">
                        Select one if you want us to send you information in a
                        language other than English{' '}
                    </span>
                    <div id="language-select">
                        <Field
                            name="enrollment[enrolling][clientInfo][primaryLanguage]"
                            type="select"
                            aria-labelledby="language-label"
                            list={LANGUAGE_OPTIONS}
                            doEnumBasedOnListIndex
                            component={RenderItemSelector}
                            addPlaceholder
                        />
                    </div>
                </div>

                {/* Permanent Address Form*/}
                <h5 className="client-form-headers">Permanent Address</h5>
                <AddressForm
                    subForm="permanent"
                    stateList={AHC_ALLOWED_STATES}
                    group="enrolling"
                    subGroup="clientInfo"
                    disabled={loadingState}
                    zipcodeDisable
                    zipcodePopoverMessage={zipcodePopoverMessage}
                    stateDisable={!isEmpty(stateAbbrFromZip)}
                    statePopoverMessage={statePopoverMessage}
                    allowPOBox={false}
                />
                <YesNoSelector
                    name="enrollment[enrolling][clientInfo][addressMailingSame]"
                    label="Is mailing address the same as permanent address?"
                    required
                />
                {enrollment.enrolling.clientInfo['addressMailingSame'] ===
                    YES_NO_OPTION['No'].toString() && (
                    <div>
                        {/* Mailing Address Form */}
                        <h5 className="client-form-headers">Mailing Address</h5>
                        <AddressForm
                            subForm="mailing"
                            stateList={ALL_STATES}
                            group="enrolling"
                            subGroup="clientInfo"
                        />
                    </div>
                )}
                <h5 className="enrollment-title my-4">Contact Information</h5>
                <div className="row">
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][primaryPhone]"
                            type="text"
                            required
                            component={RenderField}
                            normalize={normalizePhone}
                            label="Home Phone Number"
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][cellPhone]"
                            type="text"
                            component={RenderField}
                            normalize={normalizePhone}
                            label="Cell Phone Number"
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][contactEmail]"
                            type="text"
                            charLimit={LENGTH_RESTRICTIONS.EMAIL}
                            component={RenderField}
                            label="Email"
                            required={isEmailCommunicationPreference}
                        />
                    </div>
                </div>
                {/* Emergency Contact Information Form */}
                <h5 className="enrollment-title my-4" ref={emergencyContactRef}>
                    Emergency Contact Information
                </h5>
                <div className="row">
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][emergencyFirstName]"
                            type="text"
                            charLimit={LENGTH_RESTRICTIONS.FIRST_NAME}
                            component={RenderField}
                            label="First Name"
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][emergencyMiddleInitial]"
                            type="text"
                            charLimit={LENGTH_RESTRICTIONS.MIDDLE_INITIAL}
                            component={RenderField}
                            label="Middle Initial"
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][emergencyLastName]"
                            type="text"
                            charLimit={LENGTH_RESTRICTIONS.LAST_NAME}
                            component={RenderField}
                            label="Last Name"
                        />
                    </div>
                    <div className="col-md-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][emergencyRelationship]"
                            type="select"
                            list={RELATIONSHIP_OPTIONS}
                            valueMap={RELATIONSHIP_MAP}
                            component={RenderItemSelector}
                            label="Relationship"
                            addPlaceholder
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][emergencyPhone]"
                            type="text"
                            component={RenderField}
                            normalize={normalizePhone}
                            label="Phone Number"
                        />
                    </div>
                    <div className="col-md-3">
                        <FieldTrimmed
                            name="enrollment[enrolling][clientInfo][emergencyEmail]"
                            type="text"
                            charLimit={LENGTH_RESTRICTIONS.EMAIL}
                            component={RenderField}
                            label="Email"
                        />
                    </div>
                </div>
                <div className="pt-5">
                    <YesNoSelector
                        name="enrollment[enrolling][clientInfo][isCurrentlyWorking]"
                        label="Do you work?"
                    />
                    <YesNoSelector
                        name="enrollment[enrolling][clientInfo][isSpouseCurrentlyWorking]"
                        label="Does your spouse work?"
                    />
                </div>
                <div className="inline-radio py-4">
                    <RadioSelector
                        name="enrollment[enrolling][clientInfo][memberInformationDelivery]"
                        list={[
                            'Braille',
                            'Large Print',
                            ...(!is2024 ? ['Audio'] : []),
                        ]}
                        addLineBreak
                        valueMap={{
                            Braille: RECEIPT_PREF.BRAILLE,
                            'Large Print': RECEIPT_PREF.LARGE_PRINT,
                            ...(!is2024 && {
                                Audio: RECEIPT_PREF.AUDIO_CD,
                            }),
                        }}
                        label="Select one if you want us to send you information in an accessible format"
                    />

                    <div id="addional-formats-info">
                        <p>
                            Please contact Alignment Health Plan at
                            1-866-634-2247 (TTY 711) if you need information in
                            an accessible format other than what’s listed above.
                            Our office hours are 8 a.m. to 8 p.m., 7 days a week
                            (except Thanksgiving and Christmas) from October 1
                            through March 31, and Monday to Friday (except
                            holidays) from April 1 through September 30.
                        </p>
                    </div>
                </div>
                <div id="plan-materials-form">
                    The following materials will be sent to you via email unless
                    you prefer to receive a printed copy. Please check below if
                    you prefer to receive a printed version.
                    <div className="px-3">
                        <Field
                            name="enrollment[enrolling][clientInfo][receiveByMailEobPartC]"
                            label="Part C Explanation of Benefits (EOB)"
                            type="checkbox"
                            component={RenderCheckbox}
                        />
                        <Field
                            name="enrollment[enrolling][clientInfo][receiveByMailEobPartD]"
                            label="Part D Explanation of Benefits (EOB)"
                            type="checkbox"
                            component={RenderCheckbox}
                        />
                        <Field
                            name="enrollment[enrolling][clientInfo][receiveByMailAnoc]"
                            label="Annual Notice of Change (ANOC)"
                            type="checkbox"
                            component={RenderCheckbox}
                        />
                    </div>
                </div>
                <div className="pb-5">
                    <div className="row">
                        <div className="col-md-3">
                            <Field
                                name="enrollment[enrolling][clientInfo][enrollerType]"
                                type="select"
                                list={Object.values(ENROLLER_TYPE)}
                                component={RenderItemSelector}
                                label="Who is completing the enrollment?"
                            />
                        </div>
                    </div>
                    {get(enrollment, 'enrolling.clientInfo.enrollerType') ==
                        ENROLLER_TYPE.REPRESENTATIVE && (
                        <>
                            <div className="row">
                                <div className="col-lg-3">
                                    <FieldTrimmed
                                        name="enrollment[enrolling][clientInfo][representativeFirstName]"
                                        type="text"
                                        required
                                        charLimit={
                                            LENGTH_RESTRICTIONS.FIRST_NAME
                                        }
                                        disabled={loadingState}
                                        component={RenderField}
                                        label="First Name"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <FieldTrimmed
                                        name="enrollment[enrolling][clientInfo][representativeMiddleInitial]"
                                        type="text"
                                        disabled={loadingState}
                                        charLimit={
                                            LENGTH_RESTRICTIONS.MIDDLE_INITIAL
                                        }
                                        component={RenderField}
                                        label="Middle Initial"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <FieldTrimmed
                                        name="enrollment[enrolling][clientInfo][representativeLastName]"
                                        type="text"
                                        required
                                        disabled={loadingState}
                                        charLimit={
                                            LENGTH_RESTRICTIONS.LAST_NAME
                                        }
                                        component={RenderField}
                                        label="Last Name"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <FieldTrimmed
                                        name="enrollment[enrolling][clientInfo][representativePhone]"
                                        type="text"
                                        component={RenderField}
                                        normalize={normalizePhone}
                                        required
                                        label="Primary Phone #"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <Field
                                        name={
                                            'enrollment[enrolling][clientInfo][representativeRelationship]'
                                        }
                                        type="select"
                                        list={RELATIONSHIP_OPTIONS}
                                        valueMap={RELATIONSHIP_MAP}
                                        label="Relationship"
                                        required
                                        component={RenderItemSelector}
                                        addPlaceholder
                                    />
                                </div>
                            </div>
                            <AddressForm
                                group="enrolling"
                                subGroup="clientInfo"
                                subForm="representative"
                                includeSuiteApt
                            />
                        </>
                    )}
                </div>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    window.analytics.identify(
                        get(enrollment, 'applicationId'),
                        {
                            name: `${get(
                                enrollment,
                                'enrolling.clientInfo.firstName'
                            )} ${get(
                                enrollment,
                                'enrolling.clientInfo.lastName'
                            )}`,
                            email: get(
                                enrollment,
                                'enrolling.clientInfo.contactEmail'
                            ),
                            phone: get(
                                enrollment,
                                'enrolling.clientInfo.primaryPhone'
                            ),
                        }
                    );
                    handleSubmit(e);
                }}
            >
                <div className="d-flex justify-content-center py-5">
                    <BackButton
                        className="mr-2"
                        onClick={previousPage}
                        disabled={loadingState}
                    >
                        Back
                    </BackButton>
                    <NextButton
                        className="ml-2"
                        type="submit"
                        disabled={loadingState}
                    >
                        Continue
                    </NextButton>
                </div>
            </form>
        </div>
    );
};

const ClientInform = reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(
    connect((state) => {
        return getFormValues('wizard')(state) || {};
    })(ClientInformComponent)
);

export default ClientInform;
