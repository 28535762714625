import { API_ENDPOINT } from './api';

const urlGetConfigAllResources = '/ConfigSettings/GetAllResourceURLs';
const urlGetLicenseKeys = '/ConfigSettings/GetLicenseKeys';
const urlGetFeatureFlags = '/ConfigSettings/GetFeatureFlags';

const getAllResourceUrls = async () => {
    const url = `${API_ENDPOINT}${urlGetConfigAllResources}`;

    const response = fetch(url, {
        method: 'GET',
    }).then((response) => response.json());

    return response;
};
const getLicenseKeys = async () => {
    const url = `${API_ENDPOINT}${urlGetLicenseKeys}`;

    const response = fetch(url, {
        method: 'GET',
    }).then((response) => response.json());

    return response;
};
const getFeatureFlags = async () => {
    const url = `${API_ENDPOINT}${urlGetFeatureFlags}`;

    const response = fetch(url, {
        method: 'GET',
    })
        .then((response) => response?.json())
        .catch((err) => {
            console.log(err);
            return [];
        });

    return response;
};
export { getAllResourceUrls, getFeatureFlags, getLicenseKeys };
